<template>
  <div class="lg:cursor-none">
    <img
      class="w-full feat-image"
      :src="src?.featuredImage?.node?.mediaItemUrl"
    />
    <div class="px-5 pt-5 lg:px-8 lg:pt-11">
      <div class="txt-t">{{ formatDate(src?.date) }}</div>
      <div class="txt-l pt-2 pb-3 lg:py-5">{{ src?.title }}</div>
      <p class="">{{ src?.blogConfig?.perex }}</p>
    </div>
    <button
      class="mt-5 mx-5 md:hidden pointer-events-auto whitespace-nowrap bg-transparent border rounded-full border-black px-4 py-2 text-sm uppercase"
      @click="onClick"
    >
      {{ $t("cant-miss-it") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "FeaturedEvent",
  props: {
    src: {
      type: Object,
    },
  },
  methods: {
    onClick() {
      return navigateTo(this.localePath(`/article/${src.slug}`));
    },
    formatDate(isoDateString) {
      const date = new Date(isoDateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    },
  },
};
</script>

<style scoped lang="scss">
.txt-l {
  font-size: 26px;
}
.feat-image {
  aspect-ratio: 380 / 213;
  width: 100%;
  object-fit: cover;
}
</style>
