<template>
  <div
    class="wo-container pb-6 lg:pb-32 contrast w-full lg:w-auto relative"
    @mouseenter="
      setCursor(null, cursorAction, {
        'background-color': '#FFF',
        'z-index': '1',
        position: 'absolute',
      })
    "
  >
    <div class="flex justify-center">
      <div class="max-w-7xl relative z-40 container">
        <div class="lg:flex justify-center">
          <div
            class="p-6 pt-12 px-0 lg:p-20 lg:pb-5 whitespace-nowrap text-center"
          >
            <h2 class="h1">
              {{ $t("homepage.whyOpero.title.part1") }}
              <br class="block sm:hidden" />
              {{ $t("homepage.whyOpero.title.part2") }}
            </h2>
          </div>
        </div>
        <div class="lg:flex justify-center wo-bubles-container mt-4">
          <div
            id="wo-projects"
            ref="wo-projects"
            class="dot-wo mb-24 lg:absolute"
          >
            <NuxtImg class="" src="/img/whyOpero2.png" sizes="360px" />
            <h3
              class="pt-6 lg:pt-12 pb-2"
              v-html="$t('homepage.whyOpero.space.title')"
            />
            <div
              class="px-2 mb-8 lg:mb-0 lg:px-8 font-light"
              v-html="$t('homepage.whyOpero.space')"
            />
          </div>
          <div id="wo-networking" class="dot-wo h-auto">
            <NuxtImg
              class="rounded-full"
              src="/img/Opero_inspirace_networking_mentoring.jpg"
              sizes="360px"
            />
            <h3 class="pt-6 lg:pt-12 pb-2 relative z-40">
              {{ $t("homepage.whyOpero.inspiration.title") }}
            </h3>
            <div class="px-2 mb-8 lg:mb-0 lg:px-8 font-light relative z-40">
              {{ $t("homepage.whyOpero.inspiration.row1") }}<br />
              {{ $t("homepage.whyOpero.inspiration.row2") }}<br />
              {{ $t("homepage.whyOpero.inspiration.row3") }}<br />
              {{ $t("homepage.whyOpero.inspiration.row4") }}
            </div>
          </div>
          <div id="wo-business" ref="wo-business" class="dot-wo lg:absolute">
            <NuxtImg src="/img/whyOpero3.png" sizes="360px" />
            <h3 class="pt-6 lg:pt-12 pb-2 relative z-40">
              {{ $t("homepage.whyOpero.community.title") }}
            </h3>
            <div class="px-2 mb-8 lg:mb-0 lg:px-8 font-light relative z-40">
              {{ $t("homepage.whyOpero.community.row1")
              }}<br class="hidden lg:block" />
              {{ $t("homepage.whyOpero.community.row2")
              }}<br class="hidden lg:block" />
              {{ $t("homepage.whyOpero.community.row3") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "WhyOpero",
  props: {
    setCursor: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      gsapSpaceTimeline: null,
      gsapBusinessTimeline: null,
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    const width =
      document.body.clientWidth ||
      window.innerWidth ||
      document.documentElement.clientWidth;
    if (width >= 1024) {
      this.applyAnimations();
    }

    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    cursorAction() {
      // gsap.registerPlugin(ScrollToPlugin);
      // gsap.to(window, {duration: 1, scrollTo:"#bubbles"});
    },
    onResize() {
      const width =
        document.body.clientWidth ||
        window.innerWidth ||
        document.documentElement.clientWidth;
      // Execute your code that checks window width and then applies GSAP animations if conditions are met
      if (width >= 1024) {
        if (!this.gsapBusinessTimeline) this.applyAnimations();
      } else {
        this.gsapSpaceTimeline?.kill();
        this.gsapBusinessTimeline?.kill();

        this.gsapSpaceTimeline = null;
        this.gsapBusinessTimeline = null;

        gsap.set(this.$refs["wo-business"], { opacity: 1, left: 0 });
        gsap.set(this.$refs["wo-projects"], { opacity: 1, right: 0 });
      }
    },
    applyAnimations() {
      this.gsapBusinessTimeline = gsap.fromTo(
        this.$refs["wo-business"],
        { opacity: 0, left: 0 },
        {
          opacity: 1,
          left: 400,
          ease: "slow(0.7, 0.7, false)",
          scrollTrigger: {
            trigger: this.$refs["wo-containers"],
            start: "top -1200px",
            end: "center 1800px",
            scrub: true,
          },
        },
      );
      this.gsapSpaceTimeline = gsap.fromTo(
        this.$refs["wo-projects"],
        { opacity: 0, right: 0 },
        {
          opacity: 1,
          right: 400,
          ease: "slow(0.7, 0.7, false)",
          scrollTrigger: {
            trigger: this.$refs["wo-containers"],
            start: "top -1200px",
            end: "center 1800px",
            scrub: true,
          },
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  @media (max-width: 1024px) {
    max-width: fit-content;
  }
  max-width: 543px;
}
.wo-container {
  background-color: $color-primary;
  //background: white;
}

.contrast {
  //-webkit-filter: contrast(20);
  //background-color: $color-primary;
}

.dot-wo {
  //-webkit-backdrop-filter:blur(5px) contrast(10);
  //background-color: #1A1A1A;
  width: 360px;
  margin: 16px;
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 1024px) {
    width: 275px;
  }

  //color: #FFFFFF;
  //position: absolute;
  //webkit-filter: blur(35px);
  //filter: blur(35px);
}

.dot {
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background: black;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  color: white;
  position: relative;
  z-index: 2;
}

.wo-bubles-container {
  position: relative;
  //background: #FFC800;
  //-webkit-filter: contrast(20);
  //filter: contrast(20);
}

#wo-projects {
  //left: 5%;
  right: 500px;
  //position: absolute;
  img {
    width: 360px;
    height: 360px;
    object-fit: cover;
    @media (max-width: 1024px) {
      width: 275px;
      height: 275px;
    }
  }
}
#wo-business {
  //right: 5%;
  //position: absolute;
  img {
    width: 360px;
    height: 360px;
    object-fit: cover;
    @media (max-width: 1024px) {
      width: 275px;
      height: 275px;
    }
  }
}
#wo-networking {
  z-index: 3;

  img {
    width: 360px;
    height: 360px;
    object-fit: cover;
    @media (max-width: 1024px) {
      width: 275px;
      height: 275px;
    }
  }
}
</style>
