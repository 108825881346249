<template>
  <div class="hero-container">
    <video
      id="hero-video"
      autoplay
      loop
      muted
      playsinline
      @mouseenter="
        setCursor(imgTag, scrollTo, {
          'font-size': '34px',
          width: '100px',
          height: '100px',
          cursor: 'none',
        })
      "
    >
      <source
        src="https://be.opero.cz/wp-content/uploads/2025/04/opero_promo.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
    <Navbar
      @mouseover="
        setCursor(null, null, {
          'font-size': '14px',
          cursor: 'unset',
          'z-index': '1',
          width: '25px',
          height: '25px',
        })
      "
    />
    <div
      class="max-w-7xl w-full h-screen absolute top-0 left-1/2 -translate-x-1/2 pointer-events-none"
    >
      <div class="relative w-full h-full">
        <div class="hero-text-con px-6 py-16">
          <AnimateTitle :title="$t('homepage.title')" />
        </div>
      </div>
    </div>
    <a
      href="https://www.virtusfera.cz/opero/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button
        @mouseenter="
          setCursor(null, null, {
            'font-size': '14px',
            background: '#FFC800',
            width: '25px',
            height: '25px',
            'z-index': '-1',
          })
        "
        class="absolute bottom-6 right-6 py-2 px-4 flex items-center space-x-2"
      >
        <img src="/img/icon-virtual-tour.svg" alt="Virtual tour icon" />
        <span>{{ $t("homepage.virtual-tour") }}</span>
      </button>
    </a>
  </div>
</template>

<script>
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

export default {
  name: "Hero",
  props: {
    setCursor: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      imgTag: '<img src="/img/ArrowDown.png" />',
    };
  },
  methods: {
    scrollTo() {
      gsap.registerPlugin(ScrollToPlugin);
      gsap.to(window, { duration: 1, scrollTo: "#bubbles" });
    },
  },
};
</script>

<style scoped>
.hero-text-con {
  position: absolute;
  left: 0;
  bottom: min(250px, 15%);
}

.hero-container {
  position: relative;
  height: 100vh;
  width: calc(100vw);

  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/hero.png");

  cursor: none;
}

#hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
}

button {
  background: #ffc800;
  border-radius: 100px;
  font-weight: 400;
  padding: 4px 12px;
  &:hover {
    transition-duration: 350ms;
    img {
      filter: brightness(0) invert(1);
    }
  }
}
</style>
