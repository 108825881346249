<script setup>
import { onMounted, ref, nextTick } from "vue";
import ProjectCard from "~/components/Hero/ProjectCard.vue";

const localePath = useLocalePath();

const props = defineProps({
  projects: Array,
  setCursor: Function,
});

const projectLinks = ref([
  { title: "Yodas", link: "https://www.yodas.opero.cz/" },
]);

const settings = ref({
  itemsToShow: 1,
  snapAlign: "center",
});

const breakpoints = ref({
  500: {
    itemsToShow: 2,
    snapAlign: "center",
  },
  765: {
    itemsToShow: 2,
    snapAlign: "center",
  },
  1124: {
    itemsToShow: 2.5,
    snapAlign: "start",
  },
  1300: {
    itemsToShow: 3.3,
    snapAlign: "start",
  },
});

const currentSlide = ref(0);

const { projects } = props;
const testimonialContainer2 = ref();

onMounted(() => {
  nextTick(() => {
    const wrapper = testimonialContainer2.value?.firstElementChild;
    if (wrapper) {
      wrapper.style.display = "flex";
      wrapper.id = "draggable-wrapper";
    }
  });
});
</script>

<template>
  <div
    class="lg:flex justify-center"
  >
    <div class="max-w-7xl projects-home">
      <div
        class="flex flex-col md:flex-row py-12 p-6 md:px-6 md:p-16 gap-5 md:gap-28"
      >
        <h2 class="h1 lg:whitespace-nowrap">
          {{ $t("homepage.grow.title.part1") }} <br />
          {{ $t("homepage.grow.title.part2") }}
        </h2>
        <div class="txt-l flex-2">
          {{ $t("homepage.grow.subtitle") }}
        </div>
      </div>

      <Carousel
        ref="carousel"
        v-bind="settings"
        v-model="currentSlide"
        :breakpoints="breakpoints"
        class="testimonial-container projects-home"
        
      >
        <Slide v-for="(project, i) in projects" :key="i">
          <NuxtLinkLocale
            v-if="projectLinks.find((p) => p.title === project.title)"
            :to="projectLinks.find((p) => p.title === project.title)['link']"
          >
            <ProjectCard @mouseenter="
          setCursor($t('read-more'), scrollTo, {
            'font-size': '14px',
            width: '100px',
            height: '100px',
            cursor: 'none',
          })
        "
        @mouseleave="
          setCursor(null, null, {
            'font-size': '14px',
            'background-color': '#FFC800',
            cursor: 'none',
            'z-index': '-1',
          })
        " class="p-3 panel-projects" :src="project" />
          </NuxtLinkLocale>
          <ProjectCard @mouseenter="
          setCursor($t('read-more'), scrollTo, {
            'font-size': '14px',
            width: '100px',
            height: '100px',
            cursor: 'none',
          })
        "
        @mouseleave="
          setCursor(null, null, {
            'font-size': '14px',
            'background-color': '#FFC800',
            cursor: 'none',
            'z-index': '-1',
          })
        " v-else class="p-3 panel-projects" :src="project" />
        </Slide>
				<template #addons>
          <Navigation />
        </template>
      </Carousel>
      <div class="px-6 md:hidden">
        <div
          class="w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700 mt-4 mb-12"
        >
          <div
            class="bg-blue-600 h-1 rounded-full"
            :style="{
              width: `${
                ((Math.ceil(currentSlide) + 1) * 100) / projects?.length
              }%`,
              transition: 'width 0.3s',
              background: '#FFC800',
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background-bubble {
  background: url("assets/Union.png");
  background-repeat: no-repeat;
}

.draggable-wrapper:first-child {
  padding-right: 0;
}

.testimonial-container2::-webkit-scrollbar {
  display: none;
}

.projects-home .carousel__slide {
  align-items: unset;
}

.projects-home {
  width: 100%;
}

.testimonial-container:deep(.carousel__prev) {
	top: 25%;
	@media screen and (min-width: 1500px) {
    left: -95px;
		
  }
}
.testimonial-container:deep(.carousel__next) {
	top: 25%;
	@media screen and (min-width: 1500px) {
    right: -95px;
		
  }
}

.carousel__next {
  background: #ffc800;
  border-radius: 100%;

  &:hover {
    background: #ffc800;
  }
}
.carousel__prev {
  background: #ffc800;
  border-radius: 100%;
	left: -95px;
  &:hover {
    background: #ffc800;
  }
}

.carousel__icon {
  height: 20px !important;
}
</style>
