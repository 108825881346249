<template>
  <div>
    <div
      class="flex justify-center lg:pt-24 pt-20"
      @mouseenter="
        setCursor(null, null, {
          'font-size': '14px',
          background: '#FFC800',
          width: '25px',
          height: '25px',
          'z-index': '-1',
        })
      "
    >
      <div class="max-w-7xl">
        <div class="lg:flex px-6">
          <div class="lg:mr-52 pb-5 lg:pb-0">
            <h2 class="h1 whitespace-nowrap">
              {{ $t("homepage.featuredEvent.title.part1") }}
            </h2>
            <h2 class="h1">{{ $t("homepage.featuredEvent.title.part2") }}</h2>
          </div>
          <div class="txt-l leading-8 mt-8 md:mt-0">
            {{ $t("homepage.featuredEvent.subtitle") }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex justify-center px-6"
      @mouseenter="
        setCursor(null, null, {
          'font-size': '14px',
          background: '#FFC800',
          width: '25px',
          height: '25px',
          'z-index': '-1',
        })
      "
    >
      <div class="max-w-7xl">
        <div class="grid md:grid-cols-2 gap-20 lg:gap-5 lg:pt-16 pt-20">
          <FeaturedEvent
            v-if="featuredArticles && featuredArticles[0]"
            :src="featuredArticles[0]"
            @click="onClick(featuredArticles[0].slug)"
            @mouseenter="
              setCursor($t('more-info'), null, {
                'font-size': '14px',
                width: '100px',
                height: '100px',
              })
            "
            @mouseleave="
              setCursor(null, null, {
                'font-size': '14px',
                background: '#FFC800',
                width: '25px',
                height: '25px',
                'z-index': '-1',
              })
            "
          />
          <FeaturedEvent
            v-if="featuredArticles && featuredArticles[1]"
            :src="featuredArticles[1]"
            @click="onClick(featuredArticles[1].slug)"
            @mouseenter="
              setCursor($t('more-info'), null, {
                'font-size': '14px',
                width: '100px',
                height: '100px',
              })
            "
            @mouseleave="
              setCursor(null, null, {
                'font-size': '14px',
                background: '#FFC800',
                width: '25px',
                height: '25px',
                'z-index': '-1',
              })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import FeaturedEvent from "~/components/Hero/FeaturedEvent.vue";

const props = defineProps({
  setCursor: Function,
  featuredArticles: Array,
});
const localePath = useLocalePath();

const onClick = (slug) => {
  return navigateTo(localePath({ name: "article-id", params: { id: slug } }));
};
</script>

<style scoped></style>
