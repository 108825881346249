<!-- eslint-disable vue/no-v-html -->
<script setup>
import { gsap } from "gsap";
import BubbleText from "~/components/Hero/BubbleText.vue";
const { t } = useI18n();
const route = useRoute();

useHeadMeta({
  title: t("homepage.meta.title"),
  description: t("homepage.meta.description"),
  url: route.fullPath,
  image:
    "https://www.opero.cz/img/Opero_byznys_hub_cowork_eventspace_pronajem_prostor.jpg",
  alt: "Opero",
});

const localePath = useLocalePath();

const loading = ref(false);

const cursor = ref("");
const cursorStyle = ref({});
const cursorOnClick = ref(() => {});

const updateWidth = () => {
  width.value =
    document.body.clientWidth ||
    window.innerWidth ||
    document.documentElement.clientWidth;
  if (width.value > 1024 || matchMedia("(pointer:fine)").matches) {
    gsap.set("#cursor", { xPercent: -50, yPercent: -50 });

    xTo.value = gsap.quickTo("#cursor", "x", {
      duration: 0.1,
      ease: "power3",
    });
    yTo.value = gsap.quickTo("#cursor", "y", {
      duration: 0.1,
      ease: "power3",
    });
  }
};

const setCursor = (value, onClick, style) => {
  cursor.value = value;
  if (cursorOnClick.value !== onClick) {
    window.document.removeEventListener("click", cursorOnClick.value);
    cursorOnClick.value = onClick;

    window.document.addEventListener("click", cursorOnClick.value);
  }
  if (cursorStyle.value !== style) {
    cursorStyle.value = style;
  }
};

const width = ref(null);

const xTo = ref(null);
const yTo = ref(null);

const mouseMove = (e) => {
  if (width.value > 1024 || matchMedia("(pointer:fine)").matches) {
    xTo.value(e.pageX);
    yTo.value(e.pageY);
  }
};

onMounted(() => {
  loading.value = false;

  width.value =
    document.body.clientWidth ||
    window.innerWidth ||
    document.documentElement.clientWidth;
  if (width.value > 1024 || matchMedia("(pointer:fine)").matches) {
    gsap.set("#cursor", { xPercent: -50, yPercent: -50 });

    xTo.value = gsap.quickTo("#cursor", "x", {
      duration: 0.1,
      ease: "power3",
    });
    yTo.value = gsap.quickTo("#cursor", "y", {
      duration: 0.1,
      ease: "power3",
    });
  }

  window.addEventListener("resize", updateWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateWidth);
  window.document.removeEventListener("click", cursorOnClick.value);
});

const { locale } = useI18n();
const query = gql`
  query CustomPages {
    cpages(where: {language: ${locale.value
      .toString()
      .toUpperCase()}, pageType: "homepage"}) {
    nodes {
      slug
      title
      homepageconfiguration {
        pageType
        featuredItem {
          ... on Event {
            id
            title
            uri
            link
            slug
						date
            eventsConfig {
              title
              perex
              datesettings {
                timeEnd
                timeFrom
              }
              featuredImage {
                mediaItemUrl
                sourceUrl
                mediaDetails {
                  sizes {
                    width
                    sourceUrl
                  }
                }
              }
            }
          }
          ... on Post {
            id
            title
            date
            title
            uri
            link
            slug
            status

            blogConfig {
              perex
              content
            }
            featuredImage {
              node {
               mediaItemUrl
                sourceUrl
                mediaDetails {
                  sizes {
                    width
                    sourceUrl
                  }
                }
              }
            }
          }
        }


        partners {
          id
          slug
          mediaDetails {
            sizes {
              sourceUrl
              width
            }
          }
        }
        projects {
          ... on Project {
            id
            title
            content
            projectsConfig {
             posts {
                    ... on Post {
                      id
                      slug
                    }
                  }
                content
                  images {
                    image {
                      mediaItemUrl
                    }
                    logo {
                      mediaItemUrl
                    }
                  }
                }

          }
        }
        featuredArticles {
          ... on Post {
            id
            title
            content
            slug
            date
            featuredImage {
              node {
                id
                mediaItemUrl
              }
            }
             blogConfig {
                perex
                content
              }
          }
        }
      }
    }
  }
}

`;

const variables = { limit: 5 };
const { data } = await useAsyncQuery(query, variables);
</script>

<template>
  <div @mousemove="mouseMove">
    <div v-if="data" class="homeP">
      <Hero :set-cursor="setCursor" />
      <BubbleText :set-cursor="setCursor">
        <div
          class="bubbleText lg:absolute relative z-10 py-4 pt-12 lg:py-24 w-full max-w-7xl"
        >
          <div class="bubble-text pointer-events-none">
            <div class="lg:mt-0 scale-95">
              <AnimatePerex :title="$t('hp.perex')" />
              <div class="sm:flex mt-4 lg:mt-8 gap-6">
                <div class="mb-4 lg:mb-0">
                  <button
                    class="pointer-events-auto bg-transparent border rounded-full border-black px-4 py-2 text-sm"
                    @click="
                      navigateTo({
                        path: localePath({ name: 'contact' }),
                        query: { from: 'membership' },
                      })
                    "
                  >
                    {{ $t("homepage.perex.button1") }}
                  </button>
                </div>

                <NuxtLinkLocale to="membership">
                  <button
                    class="pointer-events-auto bg-transparent border rounded-full border-black px-4 py-2 text-sm whitespace-nowrap"
                  >
                    {{ $t("homepage.perex.button2") }}
                  </button></NuxtLinkLocale
                >
              </div>
            </div>
          </div>
        </div>
      </BubbleText>
      <Bubbles
        :key="width"
        class="hidden lg:block"
        :set-cursor="setCursor"
        :height="580"
        :start-width="600"
        ref-prop="canvasHome"
      />

      <div
        v-if="data?.cpages?.nodes[0]?.homepageconfiguration?.featuredItem"
        class="lg:flex justify-center"
        @mouseenter="
          setCursor(null, null, {
            'font-size': '14px',
            background: '#FFC800',
            width: '25px',
            height: '25px',
            'z-index': '-1',
          })
        "
      >
        <div class="max-w-7xl w-full">
          <div class="lg:flex mt-12 lg:mt-0 px-8 lg:pl-0 lg:ml-6">
            <h2 class="h1 lg:p-0 pb-0 lg:pb-0">
              {{ $t("homepage.actionList.title") }}
            </h2>
          </div>
        </div>
      </div>

      <ActionList
        v-if="data?.cpages?.nodes[0]?.homepageconfiguration?.featuredItem"
        :featured-item="data.cpages.nodes[0].homepageconfiguration.featuredItem"
        :set-cursor="setCursor"
      />
      <WhyOpero
        :projects="data.cpages.nodes[0].homepageconfiguration.yellowbanner"
        :set-cursor="setCursor"
      />

      <div
        class="lg:flex justify-center"
        @mouseenter="
          setCursor(null, null, {
            'font-size': '14px',
            background: '#FFC800',
            width: '25px',
            height: '25px',
            'z-index': '-1',
          })
        "
      >
        <div class="max-w-7xl">
          <div
            class="flex flex-col md:flex-row px-6 md:px-6 pb-12 lg:pb-16 pt-20 lg:pt-24 gap-5 md:gap-32"
          >
            <h2 class="h1">
              <span class="whitespace-nowrap">{{
                $t("homepage.joinUs.title.part1")
              }}</span>
              <br class="block lg:hidden" />
              <div class="whitespace-nowrap">
                {{ $t("homepage.joinUs.title.part2") }}&nbsp;
              </div>
            </h2>
            <div class="txt-l flex-2">
              {{ $t("homepage.joinUs.subtitle") }}
            </div>
          </div>
        </div>
      </div>

      <JoinUs :set-cursor="setCursor" />
      <ProjectsHome
        :set-cursor="setCursor"
        :projects="data.cpages.nodes[0].homepageconfiguration.projects"
      />

      <div class="flex justify-center">
        <button
          class="pointer-events-auto bg-transparent border rounded-full border-black px-4 py-2 text-sm mb-16"
          @click="navigateTo({ path: localePath({ name: 'projects' }) })"
        >
          {{ $t("homepage.allprojects") }}
        </button>
      </div>

      <BecomeMember :set-cursor="setCursor" />
      <Partners
        :partners="data.cpages.nodes[0].homepageconfiguration.partners"
        :set-cursor="setCursor"
        :is-projects="false"
      />
      <FeaturedEvents
        :set-cursor="setCursor"
        :featured-articles="
          data.cpages.nodes[0].homepageconfiguration.featuredArticles
        "
      />
      <Footer id="newsletter" :set-cursor="setCursor" class="lg:mt-36 mt-24" />
    </div>
    <div v-if="!data">Loading</div>
    <div>
      <div
        id="cursor"
        class="hidden pointer-events-none cursor-auto lg:cursor-none lg:flex"
        :style="cursorStyle"
        :class="{
          smallCursor: cursor === null,
          hidden: !width || width < 1024,
        }"
        v-html="cursor"
      ></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#cursor {
  position: absolute;
  top: 0;
  left: -10px;
  width: 25px;
  height: 25px;
  font-weight: 400;

  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  border-radius: 50%;
  z-index: 1000;
  cursor: none;
  transition:
    width 0.3s,
    height 0.3s,
    background-color 0.3s;
  transform: translate(-50%, -50%) translate(-150px, -150px);
  //pointer-events: none;
  text-align: center;
  display: none;
  @media screen {
    @media (pointer: fine) {
      display: flex;
    }
  }
}

.smallCursor {
  width: 20px;
  height: 20px;
  background-color: $color-primary;
}

.negative-margin {
  @media screen and (max-width: 450px) {
    margin-left: -7px;
  }
  //margin-top: -9px;
  //margin-bottom: -9px;
}
</style>

<style lang="scss">
.homeP .bubbleText {
  //background: red;
  padding-left: 0;
  margin-left: -8px;
}
</style>
