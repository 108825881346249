<template>
  <div
    class="lg:flex justify-center"
  >
    <div class="max-w-6xl px-6 xl:px-0">
      <Carousel
        ref="carousel"
        v-model="activeSlide"
        class="testimonial-container"
        @slide-start="slideChange"
        @mouseenter="
          setCursor(null, null, {
            'font-size': '14px',
            cursor: 'pointer',
						'z-index': '-50',
          })
        "
        @mouseleave="
          setCursor(null, null, {
            'font-size': '14px',
            'background-color': '#FFC800',
          })
        "
      >
        <Slide key="2">
          <div class="panel md:grid grid-cols-2 gap-4 min-w-full">
            <!--    TODO video-->
            <img
              src="/img/Vit_Horky_czech_founders_opero.jpg"
              class="w-50 w-full"
              sizes="md:600px"
            />
            <div class="lg:p-8 h-full hidden md:block">
              <div
                class="txt-q mt-4 lg:mt-0 mb-6 lg:mb-12"
                v-html="$t('homepage.joinUs.testimonials.vit.text')"
              />
              <div>
                <h3 class="font-light">
                  {{ $t("homepage.joinUs.testimonials.vit.name") }}
                </h3>
              </div>
              <div class="txt-t">
                {{ $t("homepage.joinUs.testimonials.vit.position") }}
              </div>
            </div>
          </div>
        </Slide>

        <Slide key="3">
          <div class="panel md:grid grid-cols-2 gap-4 min-w-full">
            <!--    TODO video-->
            <img
              src="/img/tomas_sedlacek_opero.jpg"
              class="w-50 w-full"
              sizes="md:600px"
            />
            <div class="lg:p-8 h-full hidden md:block">
              <div class="txt-q mt-4 lg:mt-0 mb-6 lg:mb-12">
                {{ $t("homepage.joinUs.testimonials.tomas.text") }}
              </div>
              <div>
                <h3 class="font-light">
                  {{ $t("homepage.joinUs.testimonials.tomas.name") }}
                </h3>
              </div>
              <div class="txt-t">
                {{ $t("homepage.joinUs.testimonials.tomas.position") }}
              </div>
            </div>
          </div>
        </Slide>
        <Slide key="1">
          <div class="panel md:grid grid-cols-2 gap-4 min-w-full">
            <!--    TODO video-->
            <img
              src="/img/louzenska_pavlina_opero.jpg"
              class="w-50 w-full"
              sizes="md:600px"
            />
            <div class="lg:p-8 h-full hidden md:block">
              <div class="txt-q mt-4 lg:mt-0 mb-6 lg:mb-12">
                {{ $t("homepage.joinUs.testimonials.pavlina.text") }}
              </div>
              <div>
                <h3 class="font-light">
                  {{ $t("homepage.joinUs.testimonials.pavlina.name") }}
                </h3>
              </div>
              <div class="txt-t">
                {{ $t("homepage.joinUs.testimonials.pavlina.position") }}
              </div>
            </div>
          </div>
        </Slide>

        <Slide key="0">
          <div class="panel md:grid grid-cols-2 gap-4 min-w-full">
            <!--    TODO video-->
            <img
              src="/img/holecko.png"
              class="w-50 test-img"
              sizes="md:600px"
            />
            <div class="lg:p-8 h-full hidden md:block">
              <div class="txt-q mt-4 lg:mt-0 mb-6 lg:mb-12">
                {{ $t("homepage.joinUs.testimonials.holecko.text") }}
              </div>
              <div>
                <h3 class="font-light">
                  {{ $t("homepage.joinUs.testimonials.holecko.name") }}
                </h3>
              </div>
              <div class="txt-t">
                {{ $t("homepage.joinUs.testimonials.holecko.position") }}
              </div>
            </div>
          </div>
          <!--                <div class="carousel__item">{{ slide }}</div>-->
        </Slide>

        <Slide key="4">
          <div class="panel md:grid grid-cols-2 gap-4 min-w-full">
            <!--    TODO video-->
            <img src="/img/Katerina.jpg" class="w-50 w-full" sizes="md:600px" />
            <div class="lg:p-8 h-full hidden md:block">
              <div class="txt-q mt-4 lg:mt-0 mb-6 lg:mb-12">
                {{ $t("homepage.joinUs.testimonials.samanova.text") }}
              </div>
              <div>
                <h3 class="font-light">
                  {{ $t("homepage.joinUs.testimonials.samanova.name") }}
                </h3>
              </div>
              <div class="txt-t">
                {{ $t("homepage.joinUs.testimonials.samanova.position") }}
              </div>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>

      <div
        class="md:mb-6 pt-6 flex justify-center"
        @mouseenter="
          setCursor(null, null, {
            'font-size': '14px',
            cursor: 'unset',
            'z-index': '-50',
            visibility: 'hidden',
          })
        "
        @mouseleave="
          setCursor(null, null, {
            'font-size': '14px',
            cursor: 'none',
            'background-color': '#FFC800',
          })
        "
      >
        <BubblesNavigationPaper
          :height="50"
          :width="40 * testimonials.length"
          start-width="50"
          :testimonials="testimonials"
          :change-testimonial="changeTestimonial"
          :current-selected="currentTestimonial"
          class="lg:mb-12"
        />
      </div>

      <Carousel
        ref="carousel"
        v-model="activeSlide"
        class="testimonial-container md:hidden"
        @slide-start="slideChange"
        @mouseenter="
          setCursor(imgTag, scrollTo, {
            'font-size': '34px',
            width: '100px',
            height: '100px',
            cursor: 'none',
          })
        "
        @mouseleave="
          setCursor(null, null, {
            'font-size': '14px',
            'background-color': '#FFC800',
          })
        "
      >
        <Slide key="2">
          <div class="panel md:grid grid-cols-2 gap-4 min-w-full">
            <!--    TODO video-->
            <div class="lg:p-8 h-full">
              <div class="txt-q mt-4 lg:mt-0 mb-6 lg:mb-12">
                {{ $t("homepage.joinUs.testimonials.vit.text") }}
              </div>
              <div>
                <h3 class="font-light">
                  {{ $t("homepage.joinUs.testimonials.vit.name") }}
                </h3>
              </div>
              <div class="txt-t">
                {{ $t("homepage.joinUs.testimonials.vit.position") }}
              </div>
            </div>
          </div>
        </Slide>

        <Slide key="3">
          <div class="panel md:grid grid-cols-2 gap-4 min-w-full">
            <!--    TODO video-->
            <div class="lg:p-8 h-full">
              <div class="txt-q mt-4 lg:mt-0 mb-6 lg:mb-12">
                {{ $t("homepage.joinUs.testimonials.tomas.text") }}
              </div>
              <div>
                <h3 class="font-light">
                  {{ $t("homepage.joinUs.testimonials.tomas.name") }}
                </h3>
              </div>
              <div class="txt-t">
                {{ $t("homepage.joinUs.testimonials.tomas.position") }}
              </div>
            </div>
          </div>
        </Slide>
        <Slide key="1">
          <div class="panel md:grid grid-cols-2 gap-4 min-w-full">
            <!--    TODO video-->
            <div class="lg:p-8 h-full">
              <div class="txt-q mt-4 lg:mt-0 mb-6 lg:mb-12">
                {{ $t("homepage.joinUs.testimonials.pavlina.text") }}
              </div>
              <div>
                <h3 class="font-light">
                  {{ $t("homepage.joinUs.testimonials.pavlina.name") }}
                </h3>
              </div>
              <div class="txt-t">
                {{ $t("homepage.joinUs.testimonials.pavlina.position") }}
              </div>
            </div>
          </div>
        </Slide>

        <Slide key="0">
          <div class="panel md:grid grid-cols-2 gap-4 min-w-full">
            <!--    TODO video-->
            <div class="lg:p-8 h-full">
              <div class="txt-q mt-4 lg:mt-0 mb-6 lg:mb-12">
                {{ $t("homepage.joinUs.testimonials.holecko.text") }}
              </div>
              <div>
                <h3 class="font-light">
                  {{ $t("homepage.joinUs.testimonials.holecko.name") }}
                </h3>
              </div>
              <div class="txt-t">
                {{ $t("homepage.joinUs.testimonials.holecko.position") }}
              </div>
            </div>
          </div>
          <!--                <div class="carousel__item">{{ slide }}</div>-->
        </Slide>

        <Slide key="4">
          <div class="panel md:grid grid-cols-2 gap-4 min-w-full">
            <!--    TODO video-->
            <div class="lg:p-8 h-full">
              <div class="txt-q mt-4 lg:mt-0 mb-6 lg:mb-12">
                {{ $t("homepage.joinUs.testimonials.samanova.text") }}
              </div>
              <div>
                <h3 class="font-light">
                  {{ $t("homepage.joinUs.testimonials.samanova.name") }}
                </h3>
              </div>
              <div class="txt-t">
                {{ $t("homepage.joinUs.testimonials.samanova.position") }}
              </div>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Draggable from "gsap/Draggable";
import { Carousel, Navigation, Slide, Pagination } from "vue3-carousel";

export default {
  name: "Test",
  props: {
    setCursor: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      testimonials: [0, 1, 2, 3, 4],
      currentTestimonial: 0,
      sections: [],
      isScrolling: false,
      gsapAnimation: null,
      scrollingTimer: null,
      scrollPos: 0,
      scrollDir: 1,
      lastScrollPos: -1,
      el: null,
      activeSlide: 0,
      imgTag: '<img src="/img/arrowDrag.svg" />',
    };
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.keyPressed);
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    window.addEventListener("keydown", this.keyPressed);

    window.addEventListener("resize", this.handleResize);

    gsap.registerPlugin(ScrollToPlugin, Draggable);

    this.handleResize();
  },
  methods: {
    changeTestimonial(t) {
      this.$refs.carousel.slideTo(t);
      this.currentTestimonial = t;
    },
    cursorAction() {},

    handleResize() {
      // this.sections = gsap.utils.toArray(".panel");
      //
      // var startx = 0;
      //
      // Draggable.create(this.$refs["testimonial-container"], {
      //     type: "scrollLeft",
      //     lockAxis: true,
      //
      //     onPress: function (event) {
      //         startx = event.clientX;
      //
      //
      //     },
      // });
      // this.$nextTick(() => {
      //     // const wrapper = this.$refs["testimonial-container"].firstElementChild;
      //     // if (wrapper) {
      //     //     wrapper.style.display = "flex";
      //     //     wrapper.id = "draggable-wrapper";
      //     // }
      //     //
      //     // // const wrapper = this.target.firstElementChild;
      //     // if (wrapper && !wrapper.classList.contains("draggable-wrapper")) {
      //     //     wrapper.classList.add("draggable-wrapper");
      //     // }
      //
      //
      //
      //     // let isScrollingTimer;
      //     // this.scrollPos = 0;
      //     // this.scrollDir = 1;
      //     // this.lastScrollPos = -1;
      //     // this.el = this.$refs[`testimonial-container`];
      //     //
      //     // this.el.addEventListener('scroll', this.handleScroll);
      // });
    },
    slideChange(data) {
      this.currentTestimonial = data.slidingToIndex;
    },
    keyPressed(event) {
      if (event.code === "ArrowLeft" && this.currentTestimonial > 0) {
        this.changeTestimonial(this.currentTestimonial - 1);
      } else if (
        event.code === "ArrowRight" &&
        this.currentTestimonial < this.testimonials.length - 1
      ) {
        this.changeTestimonial(this.currentTestimonial + 1);
      }
    },
  },
};
</script>

<style>
.testimonial-container {
  text-align: left;
}

.testimonial-container::-webkit-scrollbar {
  display: none;
}
.draggable-wrapper {
  display: flex !important;
}
</style>

<style scoped lang="scss">
.panel {
  height: 100%;
}

.txt-q {
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.panel img {
  /*width: 600px;*/
  //height: 100%;
  height: -webkit-fill-available;
  object-fit: cover;
  max-height: 415px;
  @media screen and (max-width: 1024px) {
    height: -webkit-fill-available;
  }
  @media screen and (max-width: 768px) {
    height: unset;
  }
}

.test-img {
  height: -webkit-fill-available;
  //max-height: 400px;
  //margin-top: 24px;
  object-fit: cover;
}
</style>

<style lang="scss" scoped>
.testimonial-container:deep(.carousel__prev) {
	@media screen and (min-width: 1280px) {
    left: -95px;
  }
}
.testimonial-container:deep(.carousel__next) {
	@media screen and (min-width: 1280px) {
    right: -95px;
  }
}
.carousel__next {
  background: #ffc800;
  border-radius: 100%;

  &:hover {
    background: #ffc800;
  }
}
.carousel__prev {
  background: #ffc800;
  border-radius: 100%;
  &:hover {
    background: #ffc800;
  }
}

.carousel__icon {
  height: 20px !important;
}
</style>
